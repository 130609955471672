import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26')
];

export const server_loads = [0,4];

export const dictionary = {
		"/": [8],
		"/(customer)/bill": [13,[3]],
		"/(customer)/checkout": [~14,[3]],
		"/delete-account": [19],
		"/faq": [20],
		"/menu": [21,[5],[6]],
		"/menu/[itemId]": [22,[5,7],[6]],
		"/(customer)/pay": [~15,[3]],
		"/(authorized)/profile/account": [~9,[2]],
		"/(authorized)/profile/order": [~10,[2]],
		"/(authorized)/profile/payment-method": [~11,[2]],
		"/(authorized)/profile/transaction": [~12,[2]],
		"/(customer)/reciept": [16,[3]],
		"/register": [~23],
		"/signin": [~24],
		"/staff-tips/[staffId]": [25],
		"/(customer)/table": [18,[3,4]],
		"/(customer)/tab": [17,[3]],
		"/version": [26]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';