import { PUBLIC_SENTRY_DSN } from '$env/static/public';
import * as Sentry from '@sentry/sveltekit';
import { PUBLIC_NODE_ENV } from '$env/static/public';
// If you don't want to use Session Replay, remove the `Replay` integration,
// `replaysSessionSampleRate` and `replaysOnErrorSampleRate` options.
Sentry.init({
	dsn: PUBLIC_SENTRY_DSN,
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1,
	integrations: [
		Sentry.replayIntegration(),
		// Add browser profiling integration to the list of integrations
		new Sentry.BrowserProfilingIntegration()
	],
	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,
	// Set profilesSampleRate to 1.0 to profile every transaction.
	// Since profilesSampleRate is relative to tracesSampleRate,
	// the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
	// For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
	// results in 25% of transactions being profiled (0.5*0.5=0.25)
	profilesSampleRate: 1.0,

  enabled:PUBLIC_NODE_ENV !== 'development',
  environment: PUBLIC_NODE_ENV,

});

export const handleError = Sentry.handleErrorWithSentry();
